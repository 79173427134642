import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'

import { Link } from 'react-router-dom'
import {Swiper, SwiperSlide} from 'swiper/react'
import { Autoplay } from 'swiper'
import {Modal, ModalBody} from 'react-bootstrap';

import AOS from 'aos';
import 'aos/dist/aos.css';

import galeryImg1 from "./../images/productos/galeria/1.jpg";
import galeryImg2 from "./../images/productos/galeria/2.jpg";
import galeryImg3 from "./../images/productos/galeria/3.jpg";
import galeryImg4 from "./../images/productos/galeria/4.jpg";
import galeryImg5 from "./../images/productos/galeria/5.jpg";
import galeryImg6 from "./../images/productos/galeria/6.jpg";
import galeryImg7 from "./../images/productos/galeria/7.jpg";
import galeryImg8 from "./../images/productos/galeria/8.jpg";

import icon1 from "./../images/cualidades/icon1.png"
import icon2 from "./../images/cualidades/icon2.png"
import icon3 from "./../images/cualidades/icon3.png"
import icon4 from "./../images/cualidades/icon4.png"

import marca1 from "./../images/cualidades/marca1.png"
import marca2 from "./../images/cualidades/marca2.png"
import marca3 from "./../images/cualidades/marca3.png"
import marca4 from "./../images/cualidades/marca4.png"
import marca5 from "./../images/cualidades/marca5.png"
import marca6 from "./../images/cualidades/marca6.png"
import marca7 from "./../images/cualidades/marca7.png"
import marca8 from "./../images/cualidades/marca8.png"
import marca9 from "./../images/cualidades/marca9.png"
import marca10 from "./../images/cualidades/marca10.png"

// MATERIALES ARAUCCO
import material6 from "./../images/cualidades/arauco/Blanco-absoluto.jpg"
import material7 from "./../images/cualidades/arauco/Blanco-frosty.jpg"
import material8 from "./../images/cualidades/arauco/cairo.jpg"
import material9 from "./../images/cualidades/arauco/caoba.jpg"
import material10 from "./../images/cualidades/arauco/cerezo.jpg"
import material11 from "./../images/cualidades/arauco/cerezo-premium.jpg"
import material12 from "./../images/cualidades/arauco/durango.jpg"
import material13 from "./../images/cualidades/arauco/ebano-indi.jpg"
import material14 from "./../images/cualidades/arauco/encino-polar.jpg"
import material15 from "./../images/cualidades/arauco/fresno-bruma.jpg"
import material16 from "./../images/cualidades/arauco/Gris.jpg"
import material17 from "./../images/cualidades/arauco/Gris-claro.jpg"
import material18 from "./../images/cualidades/arauco/laricina-siberia.jpg"
import material19 from "./../images/cualidades/arauco/metropolitan.jpg"
import material20 from "./../images/cualidades/arauco/monarca.jpg"
import material21 from "./../images/cualidades/arauco/nebraska.jpg"
import material22 from "./../images/cualidades/arauco/Negro.jpg"
import material23 from "./../images/cualidades/arauco/nogal-britanico.jpg"
import material24 from "./../images/cualidades/arauco/nogal-neo.jpg"
import material25 from "./../images/cualidades/arauco/nogal-urbano.jpg"
import material26 from "./../images/cualidades/arauco/Oxford.jpg"
import material27 from "./../images/cualidades/arauco/oyamel.jpg"
import material28 from "./../images/cualidades/arauco/precompuesto-ceniza.jpg"
import material29 from "./../images/cualidades/arauco/roble-merida.jpg"
import material30 from "./../images/cualidades/arauco/roble-santana.jpg"
import material31 from "./../images/cualidades/arauco/roble-tabaco.jpg"
import material32 from "./../images/cualidades/arauco/rojo-colonial.jpg"
import material33 from "./../images/cualidades/arauco/Vison.jpg"
import material34 from "./../images/cualidades/arauco/Anahuac.jpg"


// MATERIALES EGGER
import galstoneBlanco from "./../images/cualidades/egger/galstone-blanco.jpg"
import galstoneLava from "./../images/cualidades/egger/galstone-lava.jpg"
import galstoneTabaco from "./../images/cualidades/egger/galstone-tabaco.jpg"
import halifaxBlanco from "./../images/cualidades/egger/halifax-blanco.jpg"
import halifaxNatural from "./../images/cualidades/egger/halifax-natural.jpg"
import halifaxTabaco from "./../images/cualidades/egger/halifax-tabaco.jpg"
import halifaxZinc from "./../images/cualidades/egger/halifax-zinc.jpg"
import oolmoTossiniCrudo from "./../images/cualidades/egger/oolmo-tossini-crudo.jpg"
import shermanCognac from "./../images/cualidades/egger/sherman-cognac.jpg"

// MATERIALES HETTICH
import compasParaPuerta from "./../images/cualidades/Hettich/compas-para-puerta.jpg"
import herraje1 from "./../images/cualidades/Hettich/Herraje-1.jpg"
import herraje2 from "./../images/cualidades/Hettich/Herraje-2.jpg"
import herraje3 from "./../images/cualidades/Hettich/Herraje-3.jpg"
import herrajeElevable from "./../images/cualidades/Hettich/herraje-elevable.jpg"

// MATERIALES VESTO
import alaska from "./../images/cualidades/vesto/alaska.jpg"
import aserradoNordico from "./../images/cualidades/vesto/aserrado-nordico.jpg"
import cendraEscandinavo from "./../images/cualidades/vesto/cendra-escandinavo.jpg"
import nativa from "./../images/cualidades/vesto/nativa.jpg"
import nogalTerracota from "./../images/cualidades/vesto/nogal-terracota.jpg"
import riviera from "./../images/cualidades/vesto/riviera.jpg"
import robleAmericano from "./../images/cualidades/vesto/roble-americano.jpg"
import robleProvenzal from "./../images/cualidades/vesto/roble-provenzal.jpg"
import tekaArtico from "./../images/cualidades/vesto/teka-artico.jpg"
import toscana from "./../images/cualidades/vesto/toscana.jpg"

// MATERIALES BLUM
import bisagras from "./../images/cualidades/Blum/bisagras.jpg"
import box from "./../images/cualidades/Blum/box.jpg"
import divisiones from "./../images/cualidades/Blum/divisiones.jpg"
import guias from "./../images/cualidades/Blum/guias.jpg"
import herraje from "./../images/cualidades/Blum/herraje.jpg"

// GALERIA
import galeria1 from "./../images/cualidades/Experiencia/1.jpg"
import galeria2 from "./../images/cualidades/Experiencia/2.jpg"
import galeria3 from "./../images/cualidades/Experiencia/3.jpg"
import galeria4 from "./../images/cualidades/Experiencia/4.jpg"


// PRODUCTOS
import producto1 from"./../images/productos/galeria1.png"
import producto2 from"./../images/productos/galeria2.png"
import producto3 from"./../images/productos/galeria3.png"
import producto4 from"./../images/productos/galeria4.png"

function Cualidades() {

    const [showModal, setShowModal] = useState(false);
    const [selectImage, setSelectImage] = useState(null);

    const handleShowModal = (src) => {
      setSelectImage(src);
      setShowModal(true);
    };

    const handleCloseModal = () => {
      setShowModal(false);
      setSelectImage(null);
    };
    
    const isActiveVerMasFalse = {
        totalVerMas: 4,
        isActive: false
    }
    
    const marcaArauco ={
        imagen: marca1,
        materiales: [
            {
                materialesDescripcion: "Blanco Absoluto",
                materialesImage: material6
            },
            {
                materialesDescripcion: "Blanco Frosty",
                materialesImage: material7
            },
            {
                materialesDescripcion: "Cairo",
                materialesImage: material8
            },
            {
                materialesDescripcion: "Caoba",
                materialesImage: material9
            },
            {
                materialesDescripcion: "Cerezo",
                materialesImage: material10
            },
            {
                materialesDescripcion: "Cerezo Premium",
                materialesImage: material11
            },
            {
                materialesDescripcion: "Durango",
                materialesImage: material12
            },
            {
                materialesDescripcion: "Ébano Indi",
                materialesImage: material13
            },
            {
                materialesDescripcion: "Encino Polar",
                materialesImage: material14
            },
            {
                materialesDescripcion: "Fresno Bruma",
                materialesImage: material15
            },
            {
                materialesDescripcion: "Gris",
                materialesImage: material16
            },
            {
                materialesDescripcion: "Gris Claro",
                materialesImage: material17
            },
            {
                materialesDescripcion: "Laricina Siberia",
                materialesImage: material18
            },
            {
                materialesDescripcion: "Metropolitan",
                materialesImage: material19
            },
            {
                materialesDescripcion: "Monarca",
                materialesImage: material20
            },
            {
                materialesDescripcion: "Nebraska",
                materialesImage: material21
            },
            {
                materialesDescripcion: "Negro",
                materialesImage: material22
            },
            {
                materialesDescripcion: "Nogal Británico",
                materialesImage: material23
            },
            {
                materialesDescripcion: "Nogal Neo",
                materialesImage: material24
            },
            {
                materialesDescripcion: "Nogal Urbano",
                materialesImage: material25
            },
            {
                materialesDescripcion: "Oxford",
                materialesImage: material26
            },
            {
                materialesDescripcion: "Oyamel",
                materialesImage: material27
            },
            {
                materialesDescripcion: "Precompuesto Ceniza",
                materialesImage: material28
            },
            {
                materialesDescripcion: "Roble Mérida",
                materialesImage: material29
            },
            {
                materialesDescripcion: "Roble Santana",
                materialesImage: material30
            },
            {
                materialesDescripcion: "Roble Tabaco",
                materialesImage: material31
            },
            {
                materialesDescripcion: "Rojo Colonial",
                materialesImage: material32
            },
            {
                materialesDescripcion: "Visón",
                materialesImage: material33
            },
            {
                materialesDescripcion: "Anáhuac",
                materialesImage: material34
            },
        ]
    }

    const [newMarca, setNewMarca] = useState(marcaArauco)
    const [verMas, setVerMas] = useState(isActiveVerMasFalse)

    const marcaEgger ={
        imagen: marca2,
        materiales: [
            {
              materialesDescripcion: "Galstone Blanco",
              materialesImage: galstoneBlanco
            },
            {
              materialesDescripcion: "Galstone Lava",
              materialesImage: galstoneLava
            },
            {
              materialesDescripcion: "Galstone Tabaco",
              materialesImage: galstoneTabaco
            },
            {
              materialesDescripcion: "Halifax Blanco",
              materialesImage: halifaxBlanco
            },
            {
              materialesDescripcion: "Halifax Natural",
              materialesImage: halifaxNatural
            },
            {
              materialesDescripcion: "Halifax Tabaco",
              materialesImage: halifaxTabaco
            },
            {
              materialesDescripcion: "Halifax Zinc",
              materialesImage: halifaxZinc
            },
            {
              materialesDescripcion: "Oolmo Tossini Crudo",
              materialesImage: oolmoTossiniCrudo
            },
            {
              materialesDescripcion: "Sherman Cognac",
              materialesImage: shermanCognac
            },
          ]
    }
    const marcaHettich ={
        imagen: marca3,
        materiales: [
            {
              materialesDescripcion: "Compas Para Puerta",
              materialesImage: compasParaPuerta
            },
            {
              materialesDescripcion: "Herraje 1",
              materialesImage: herraje1
            },
            {
              materialesDescripcion: "Herraje 2",
              materialesImage: herraje2
            },
            {
              materialesDescripcion: "Herraje 3",
              materialesImage: herraje3
            },
            {
              materialesDescripcion: "Herraje Elevable",
              materialesImage: herrajeElevable
            },
          ]
    }
    const marcaVesto ={
        imagen: marca4,
        materiales: [
            {
              materialesDescripcion: "Alaska",
              materialesImage: alaska
            },
            {
              materialesDescripcion: "Aserrado Nórdico",
              materialesImage: aserradoNordico
            },
            {
              materialesDescripcion: "Cendra Escandinavo",
              materialesImage: cendraEscandinavo
            },
            {
              materialesDescripcion: "Nativa",
              materialesImage: nativa
            },
            {
              materialesDescripcion: "Nogal Terracota",
              materialesImage: nogalTerracota
            },
            {
              materialesDescripcion: "Riviera",
              materialesImage: riviera
            },
            {
              materialesDescripcion: "Roble Americano",
              materialesImage: robleAmericano
            },
            {
              materialesDescripcion: "Roble Provenzal",
              materialesImage: robleProvenzal
            },
            {
              materialesDescripcion: "Teka Ártico",
              materialesImage: tekaArtico
            },
            {
              materialesDescripcion: "Toscana",
              materialesImage: toscana
            },
          ]
    }
    const marcaBlum ={
        imagen: marca5,
        materiales: [
            {
              materialesDescripcion: "Bisagras",
              materialesImage: bisagras
            },
            {
              materialesDescripcion: "Box",
              materialesImage: box
            },
            {
              materialesDescripcion: "Divisiones",
              materialesImage: divisiones
            },
            {
              materialesDescripcion: "Guias",
              materialesImage: guias
            },
            {
              materialesDescripcion: "Herraje",
              materialesImage: herraje
            },
          ]
    }
        
    const productos = [
        {
          id: 0,
          imagen: producto1,
          desc: "Cocinas Integrales"
        },
        {
          id: 1,
          imagen: producto2,
          desc: "Closets"
        },
        {
          id: 2,
          imagen: producto3,
          desc: "Centros de entretenimiento"
        },
        {
          id: 3,
          imagen: producto4,
          desc: "Puertas"
        }
      ]

    useEffect(() => {
        window.scrollTo(0, 0)
        // AOS.init();

    }, [])

    // useEffect(() => {
    //     AOS.refresh()
    // }, [verMas,newMarca])

    const changeMarca = marca => {
        setNewMarca(marca)
        setVerMas(isActiveVerMasFalse)
    }

    const eventVerMas = () => {
        const isActiveVerMasTrue = {
            totalVerMas: newMarca.materiales.lenght,
            isActive: true
        }
        setVerMas(isActiveVerMasTrue)

    }

    const eventVerMenos = () => {
        setVerMas(isActiveVerMasFalse)

    }

    window.onresize = (e) => {
        AOS.refresh()
    };

    return (
        <div className="overflow-hidden">
            <Container fluid className="bg-pCenter-sCover bg-cualidades min-vh-100 d-flex align-items-end"  data-aos="zoom-out-down">
                <Container className="">
                    <div className='row mb-5'>
                        <div className='col-12 text-white overflow-hidden'>
                            <h1 className="fnt-lemonmilk-light"><b className="fnt-lemonmilk-light">¡Vive tu Casa!</b></h1>
                            <h3 className='fnt-helvetica-lt-std-light d-inline-block'>
                                Cocinando la felicidad
                                <div className='border-white border-bottom border-5 mt-2' style={{width:"200%"}}></div>
                            </h3>
                        </div>
                    </div>
                </Container>
            </Container>
            <Container fluid className='bg-pCenter-sCover bg-cualidades-1' data-aos="zoom-out-up">
                <Container>
                    <div className='row py-4'>
                        <div className='col-lg-8 position-relative my-5 p-lg-5 p-3 mx-auto border-size-bottom border-size-top border-white border-5'  data-aos="zoom-out-up">
                            <div className='border-size-start border-white h-25 position-absolute top-0 start-0'></div>
                            <div className='border-size-end border-white h-25 position-absolute top-0 end-0'></div>
                            <h2  data-aos="zoom-out-down" className='fnt-helvetica-lt-std-light text-center'>La cocina es mucho más que un espacio en el que preparar la comida. Es un lugar lleno de emociones, donde las relaciones familiares cobran un protagonismo especial.</h2>
                            <div className='border-size-start border-white h-25 position-absolute bottom-0 start-0'></div>
                            <div className='border-size-end border-white h-25 position-absolute bottom-0 end-0'></div>
                        </div>
                    </div>
                </Container>
            </Container>
            <Container className='positon-relative mt-5' data-aos="fade-up">
                <div className='row'>
                    <div className='col-12 p-0 position-relative'>
                        <div className="ratio ratio-16x9 position-relative overflow-hidden">
                            <div>
                                <iframe loading="lazy" title="bloken" className='video' src="https://www.youtube.com/embed/d-AVUmvdh7Q?playlist=d-AVUmvdh7Q&amp;rel=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;controls=0&amp;enablejsapi=1&amp;showinfo=0&amp;modestbranding=1"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Container fluid className="mt-5">
                <Row data-aos="fade-right">
                    <Col lg={4} className='col-4 py-lg-5 pt-5 px-0'>
                        <div className='col-12 bg-secondary pt-5 pb-lg-4'></div>
                    </Col>
                    <Col lg={1} className='col-1 py-lg-5 pt-5 px-0'>
                        <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-lg-4'></div>
                    </Col>
                    <Col className='py-5 d-flex align-items-center'>
                        <h1 data-aos="fade-left" className="fnt-lemonmilk-light text-justify">GENERAMOS UNA <b>EXPERIENCIA <br></br>DE PRINCIPIO A FIN</b></h1>
                    </Col>
                </Row>
            </Container>
            <Container className="mb-5">
                <div className='row'>
                    <div className='col-12 mb-4 position-relative'>
                        <img loading="lazy" className="w-100 carousel-bootstrap object-fit-cover" src={galeria1} alt="cocina" />
                        <h2 className='text-white position-absolute start-50 bottom-0 translate-middle-x mb-3 w-100 px-3 text-center text-shadow'>Usamos materiales 100% importados</h2>
                    </div>
                    <div className="col-lg-4 position-relative carousel-bootstrap">
                        <img loading="lazy" className="w-100 carousel-bootstrap object-fit-cover" src={galeria2} alt="cocina" />
                        <h4 className='text-white position-absolute start-50 bottom-0 translate-middle-x mb-3 w-100 px-3 text-center text-shadow'>Procesos automatizados con maquinaria de última generación</h4>
                    </div>
                    <div className="col-lg-4 position-relative mt-4 mt-lg-0 carousel-bootstrap">
                        <img loading="lazy" className="w-100 carousel-bootstrap object-fit-cover" src={galeria3} alt="cocina" />
                        <h4 className='text-white position-absolute start-50 bottom-0 translate-middle-x mb-3 w-100 px-3 text-center text-shadow'>Instalación profesional con innovación tecnologica</h4>
                    </div>
                    <div className="col-lg-4 position-relative mt-4 mt-lg-0 carousel-bootstrap">
                        <img loading="lazy" className="w-100 carousel-bootstrap object-fit-cover" src={galeria4} alt="cocina" />
                        <h4 className='text-white position-absolute start-50 bottom-0 translate-middle-x mb-3 w-100 px-3 text-center text-shadow'>Nueva generación de diseños disruptivos</h4>
                    </div>
                </div>
            </Container>
            <Container fluid className="bg-secondary bg-opacity-25">
                <Container>
                    <div className="row py-5">
                        <div className='col-lg-4 text-center bg-white p-5'  data-aos="zoom-out-right">
                            <h2  data-aos="zoom-out-down" className='fnt-helvetica-bold'>PARA TI</h2>
                            <img data-aos="zoom-out-down" loading="lazy" src={icon1} alt="icon usuario" className="mt-5" height={100}/>
                            <p data-aos="zoom-out-down" className='fnt-helvetica-lt-std-light mt-5 text-justify'>Vive tu cocina de manera confortable y divertida, es el corazón de tu hogar.</p>
                            <b className='text-center'>¡Haz feliz a tu estomago!</b>
                        </div>
                        <div className='col-lg-4 text-center bg-dark p-5 text-white' data-aos="zoom-out-down">
                            <h2  data-aos="zoom-out-up" className='fnt-helvetica-bold'>PARA TU HOGAR</h2>
                            <img data-aos="zoom-out-up" loading="lazy" src={icon2} alt="icon usuario" className="mt-5" height={100}/>
                            <p data-aos="zoom-out-up" className='fnt-helvetica-lt-std-light mt-5 text-justify'>Te ayudamos a pintar tus sueños con algo especial, único; productos y materiales con comodidad y armonía, resistentes y con garantía.</p>
                        </div>
                        <div className='col-lg-4 text-center bg-white p-5' data-aos="zoom-out-left">
                            <h2  data-aos="zoom-out-down" className='fnt-helvetica-bold'>PARA TU FAMILIA</h2>
                            <img data-aos="zoom-out-down" loading="lazy" src={icon3} alt="icon usuario" className="mt-5" height={100}/>
                            <p data-aos="zoom-out-down" className='fnt-helvetica-lt-std-light mt-5 text-justify'>Manifiesta el amor a tu familia, convierte ese espacio en el lugar que todos quieren estar.</p>
                        </div>
                    </div>
                </Container>
            </Container>
            <Container fluid className='mt-5'>
                <div className='row'>
                    <div className='col-12' >
                        <h1 data-aos="zoom-out-right" className='text-center fnt-lemonmilk-blod'>MARCAS CERTIFICADAS</h1>
                        <div data-aos="zoom-out-left" className='col-1 border-top border-5 border-dark mx-auto'></div>
                    </div>
                </div>
            </Container>
            <Container fluid className='mt-5 bg-secondary bg-opacity-25 py-5' data-aos="zoom-out-up">
                <Container>
                    <div className='row d-flex align-items-end justify-content-around py-5'>
                        <div data-aos="zoom-out-down" className='col-lg-2 text-center'>
                            <img loading="lazy" alt="arauco" src={marca1} className="img-fluid" /><br></br>
                            <a href="#marcasCertificadas" className='py-2 px-3 btn-ver-mas border-0 d-inline-block mt-2' onClick={() => changeMarca(marcaArauco)}>Ver matreriales</a>
                        </div>
                        <div data-aos="zoom-out-up" className='col-lg-2 text-center mt-5 mt-lg-0'>
                            <img loading="lazy" alt="e egger" src={marca2} className="img-fluid" /><br></br>
                            <a href="#marcasCertificadas" className='py-2 px-3 btn-ver-mas border-0 d-inline-block mt-2' onClick={() => changeMarca(marcaEgger)}>Ver matreriales</a>
                        </div>
                        <div data-aos="zoom-out-down" className='col-lg-2 text-center mt-5 mt-lg-0'>
                            <img loading="lazy" alt="hettich" src={marca4} className="img-fluid" /><br></br>
                            <a href="#marcasCertificadas" className='py-2 px-3 btn-ver-mas border-0 d-inline-block mt-2' onClick={() => changeMarca(marcaVesto)}>Ver matreriales</a>
                        </div>
                        <div data-aos="zoom-out-up" className='col-lg-2 text-center mt-5 mt-lg-0'>
                            <img loading="lazy" alt="mode" src={marca3} className="img-fluid" /><br></br>
                            <a href="#marcasCertificadas" className='py-2 px-3 btn-ver-mas border-0 d-inline-block mt-2' onClick={() => changeMarca(marcaHettich)}>Ver matreriales</a>
                        </div>
                        <div data-aos="zoom-out-down" className='col-lg-2 text-center mt-5 mt-lg-0'>
                            <img loading="lazy" alt="blum" src={marca5} className="img-fluid" /><br></br>
                            <a href="#marcasCertificadas" className='py-2 px-3 btn-ver-mas border-0 d-inline-block mt-2' onClick={() => changeMarca(marcaBlum)}>Ver matreriales</a>
                        </div>
                    </div>
                </Container>
            </Container>
            <Container fluid id="marcasCertificadas">
                <div className='row' data-aos="zoom-out-right">
                    <div className='col-4 py-5 px-0'>
                        <div className='col-12 bg-secondary pt-5 pb-4'></div>
                    </div>
                    <div className='col-1 py-5 px-0'>
                        <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
                    </div>
                    <div data-aos="zoom-out-down" className='col-7 col-md-4 col-lg-7 py-lg-5 pe-4 d-flex justify-content-center align-items-center justify-content-lg-between align-items-lg-end flex-column flex-lg-row'>
                        <img loading="lazy" alt="arauco" src={newMarca.imagen} className="col-12 col-lg-3" />
                        <div className="d-none d-lg-block">
                            <img loading="lazy" src={marca6} alt="iso 14001" height="35"/>
                            <img loading="lazy" src={marca7} alt="FSC" height="35"/>
                            <img loading="lazy" src={marca8} alt="ANAB" height="35"/>
                            <img loading="lazy" src={marca9} alt="SAI GLOBAL" height="35"/>
                            <img loading="lazy" src={marca10} alt="woodmark" height="35"/>
                        </div>
                    </div>
                    <div data-aos="zoom-out-down" className="d-lg-none col-12 d-flex align-items-center justify-content-around">
                        <img loading="lazy" src={marca6} alt="iso 14001" width="15%"/>
                        <img loading="lazy" src={marca7} alt="FSC" width="15%"/>
                        <img loading="lazy" src={marca8} alt="ANAB" width="15%"/>
                        <img loading="lazy" src={marca9} alt="SAI GLOBAL" width="15%"/>
                        <img loading="lazy" src={marca10} alt="woodmark" width="15%"/>
                    </div>
                </div>
            </Container>
            <Container>
                <div className='row d-flex flex-wrap align-items-center justify-content-lg-around justify-content-center my-5 transitions'>
                    {newMarca.materiales && 
                        <>
                            {newMarca.materiales.slice(0, verMas.totalVerMas).map((val, index) => {
                                return(
                                    <div key={index} className='col-md-6 col-lg-3 position-relative transitions' >
                                        <img loading="lazy" src={val.materialesImage} alt="materiales" className='img-fluid p-4 mx-auto d-block'/>
                                        <h4 className='position-absolute bottom-0 start-50 translate-middle-x text-center text-white mb-5'>{val.materialesDescripcion}</h4>
                                    </div>
                                )
                            })}
                            {newMarca.materiales.length > 4 &&
                                <div>
                                    <div className={verMas.isActive ? 'd-none' : 'd-flex justify-content-center'}>
                                        <button className='py-2 px-3 border-0 btn-ver-mas d-inline' onClick={() => eventVerMas()}>Ver más</button>
                                    </div>
                                    <div className={!verMas.isActive ? 'd-none' : 'd-flex justify-content-center'}>
                                        <a href="#marcasCertificadas" className='py-2 px-3 btn-ver-mas d-inline' onClick={() => eventVerMenos()}>Ver menos</a>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </Container>
            <Container>
                <Row className="p-4">
                    <Col className="p-4">
                        <h1 className='fnt-lemonmilk-bold text-center fw-bold'>¡CONTACTANOS PARA ESCUCHARTE!</h1>
                        <a href="tel:3322133285" target="_blank" className='btn-gray text-center mx-auto d-block btn btn-secondary border-0 rounded-0 fs-4 fnt-helvetica-lt-std-light px-3 py-2 w-50'>CLICK</a>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg-pCenter-sCover bg-cualidades-2 py-5" data-aos="zoom-out-up">
                <Container className="py-5">
                    <div className="row d-flex alignt-items-center justify-content-between flex-wrap">
                        <div className='col-lg-8 d-flex align-items-center justify-content-center'>
                            <div className='d-block text-center text-lg-start'>
                                <h1 className="text-white fnt-lemonmilk-blod ms-lg-5 display-4 mb-0" data-aos="zoom-out-right">SOMOS PARTE DEL</h1><br className='d-none d-lg-block'></br>
                                <h1 className="text-white fnt-lemonmilk-blod ms-lg-5 display-4 ps-lg-5" data-aos="zoom-out-left">DISEÑO SOSTENIBLE</h1>
                            </div>
                        </div>
                        <div className='col-lg-2 text-center'>
                            <img data-aos="zoom-out-up" loading="lazy" src={icon4} alt="marca" className='img-fluid '/>
                        </div>
                    </div>
                </Container>
            </Container>
                <Container fluid className="mt-5" data-aos="fade-right">
                    <Row>
                        <Col lg={4} className='col-4 py-5 px-0'>
                            <div className='col-12 bg-secondary pt-5 pb-4'></div>
                        </Col>
                        <Col lg={1} className='col-1 py-5 px-0'>
                            <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
                        </Col>
                        <Col className='py-5 d-flex align-items-center'>
                            <h1 data-aos="fade-up" className="fnt-lemonmilk-light text-justify">GALERÍA DE <b>PRODUCTOS</b></h1>
                        </Col>
                    </Row>
                </Container>
                <Container>

            <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            autoplay={{
                delay: 1500,
                disableOnInteraction: false
            }}
            breakpoints={{
                576: {
                slidesPerView: 1,
                },
                768:{
                slidesPerView: 2
                },
                992: {
                slidesPerView: 3
                },
                1250:{
                slidesPerView: 4
                }
            }}
            modules={[Autoplay]}
            className='galeriaSwiper mb-5'
            >
                <SwiperSlide className='galeriaItem'>
                <img src={galeryImg1} onClick={() => handleShowModal(galeryImg1)} alt="Imagen Banner 1" />
                </SwiperSlide>
                <SwiperSlide className='galeriaItem'>
                <img src={galeryImg2} onClick={() => handleShowModal(galeryImg2)} alt="Imagen Banner 2" />
                </SwiperSlide>
                <SwiperSlide className='galeriaItem'>
                <img src={galeryImg3} onClick={() => handleShowModal(galeryImg3)} alt='Imagen Banner 3' />
                </SwiperSlide>
                <SwiperSlide className='galeriaItem'>
                <img src={galeryImg4} onClick={() => handleShowModal(galeryImg4)} alt='Imagen Banner 4' />
                </SwiperSlide>
                <SwiperSlide className='galeriaItem'>
                <img src={galeryImg5} onClick={() => handleShowModal(galeryImg5)} alt='Imagen Banner 5' />
                </SwiperSlide>
                <SwiperSlide className='galeriaItem'>
                <img src={galeryImg6} onClick={() => handleShowModal(galeryImg6)} alt='Imagen Banner 6' />
                </SwiperSlide>
                <SwiperSlide className='galeriaItem'>
                <img src={galeryImg7} onClick={() => handleShowModal(galeryImg7)} alt='Imagen Banner 7' />
                </SwiperSlide>
                <SwiperSlide className='galeriaItem'>
                <img src={galeryImg8} onClick={() => handleShowModal(galeryImg8)} alt='Imagen Banner 8' />
                </SwiperSlide>
            </Swiper>


            {/* Modal para ver las imagenes de galeria */}

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <ModalBody className='p-3 position-relative'>
                {selectImage && <img src={selectImage} className='w-100' alt='Imagen Banner'/>}
                    <div className='position-absolute p-3 z-2 top-0 end-0'>
                    <button onClick={() => handleCloseModal()} className='btn btn-close'></button>
                    </div>
                </ModalBody>
            </Modal>
            
            </Container>
        </div>
    )
}

export default Cualidades