import React, { useState } from 'react'

import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import {Modal, ModalBody} from 'react-bootstrap';
import Col from 'react-bootstrap/esm/Col'
import {Swiper, SwiperSlide} from 'swiper/react'

import headerImg1 from"./../images/productos/banner-1.jpg"
import headerImg2 from"./../images/productos/banner-2.jpg"
import headerImg3 from"./../images/productos/banner-3.jpg"

import galeryImg1 from "./../images/productos/galeria/1.jpg";
import galeryImg2 from "./../images/productos/galeria/2.jpg";
import galeryImg3 from "./../images/productos/galeria/3.jpg";
import galeryImg4 from "./../images/productos/galeria/4.jpg";
import galeryImg5 from "./../images/productos/galeria/5.jpg";
import galeryImg6 from "./../images/productos/galeria/6.jpg";
import galeryImg7 from "./../images/productos/galeria/7.jpg";
import galeryImg8 from "./../images/productos/galeria/8.jpg";

import image1 from "./../images/productos/imagen1.jpg"
import rectangulo from "./../images/arqcons/rectangulo.png"

// import AOS from 'aos';
import 'aos/dist/aos.css';
import Carousel from 'react-bootstrap/Carousel'
import { Link } from 'react-router-dom'
import { Autoplay } from 'swiper'

function Productos() {
  // const [isResposive, setIsResposive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectImage, setSelectImage] = useState(null);

  const handleShowModal = (src) => {
    setSelectImage(src);
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectImage(null);
  }

  const headersBg = [
    {
      id: 0,
      desc: "Cocinas Integrales",
      image: headerImg1
    },
    {
      id: 2,
      desc: "Cocinas de lujo",
      image: headerImg2
    },
    {
      id: 1,
      desc: "Materiales 100% importados",
      image: headerImg3
    }
  ]


  // useEffect(() => {
  //   handleWidth()
  //   window.scrollTo(0, 0)
  //   AOS.init();
  // }, [])

  // const handleWidth=() =>{
  //   const bodyWidth = document.querySelector("body")
  //   if(bodyWidth.offsetWidth >= 991) return setIsResposive(true)
  //   return setIsResposive(false)
  // }

  // window.onresize = (e) => {
  //   handleWidth()
  //   AOS.refresh();

  // };

  return (
    <div className="overflow-hidden">
      <Container fluid className="p-0">
        <Carousel
          className=" w-100"
          data-aos="zoom-out-up"
          controls={true}
          indicators={true}
        >
          {headersBg.map((val, index) => {
            return (
              <Carousel.Item
                key={index}
                className="carousel-productos w-100 position-relative"
              >
                <img
                  loading="lazy"
                  alt={val.image}
                  className="w-100 h-100 object-fit-cover"
                  src={val.image}
                />
                <Link
                  to={`/productos/${val.id}`}
                  className="btn btn-transparent position-absolute bottom-0 start-50 translate-middle-x text-center mb-4"
                >
                  <h5 className="fnt-lemonmilk-blod text-white">{val.desc}</h5>
                </Link>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Container>

      <Container fluid className="mt-lg-5 mt-2">
        <Row>
          <Col lg={4} className="col-4 py-lg-5 pt-5 px-0">
            <div className="col-12 bg-secondary pt-5 pb-lg-4"></div>
          </Col>
          <Col lg={1} className="col-1 py-lg-5 pt-5 px-0">
            <div className="col-4 bg-secondary bg-opacity-75 pt-5 pb-lg-4"></div>
          </Col>
          <Col className="py-5 d-flex align-items-center">
            <h1 className="fnt-lemonmilk-light text-justify">
              LOS DETALLES <b>SON EL DISEÑO</b>
            </h1>
          </Col>
        </Row>
      </Container>
      <Container data-aos="fade-down">
        <Row className="position-relative px-4 my-5">
          <Col lg={7} style={{ zIndex: 2 }}>
            <img
              data-aos="fade-right"
              loading="lazy"
              src={image1}
              alt="cocina"
              className="img-fluid px-4"
            />
          </Col>
          <Col
            lg={12}
            className="d-none py-5 position-absolute start-0 top-50 translate-middle-y d-lg-flex justify-content-end p-5"
            style={{ zIndex: 1 }}
          >
            <Col lg={5} className="p-4">
              <h1
                data-aos="fade-left"
                className=" fnt-helvetica-bold fw-bold m-0 mb-4"
              >
                Un toque personal a tu espacio
                <div
                  data-aos="fade-up"
                  className="border-5 border-bottom border-warning w-25 mt-3"
                ></div>
              </h1>
              <p
                data-aos="fade-up"
                className="mt-3 fnt-helvetica-lt-std-light mb-0 text-justify"
              >
                Diseños que se adaptan a la medida de cualquier espacio a partir
                de una amplia capacidad compositiva.
              </p>
            </Col>
            <img
              loading="lazy"
              className="d-none d-lg-block position-absolute top-0 start-0 w-100 h-100"
              src={rectangulo}
              alt="rectangulo"
            />
          </Col>
          <Col lg={5}>
            <div className="d-lg-none">
              <h1
                className=" fnt-helvetica-bold fw-bold m-0 mb-4"
                data-aos="fade-up"
              >
                Un toque personal a tu espacio
                <div
                  data-aos="fade-up"
                  className="border-5 border-bottom border-warning w-25 mt-3"
                ></div>
              </h1>
              <p
                data-aos="fade-up"
                className="mt-3 fnt-helvetica-lt-std-light mb-0 text-justify"
              >
                Diseños que se adaptan a la medida de cualquier espacio a partir
                de una amplia capacidad compositiva.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="bg-productos-1 bg-pCenter-sCover p-5"
        data-aos="fade-top"
      >
        <Container className="py-5">
          <Row>
            <Col lg={6}>
              <h1 data-aos="fade-up" className="text-dark fnt-helvetica-bold">
                Somos tu mejor aliado
                <div
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-right"
                  className="border-5 border-bottom border-white w-25 mt-3"
                ></div>
              </h1>
              <p
                data-aos="fade-left"
                className="text-white fnt-helvetica-lt-std-light"
              >
                Estaremos contigo en la selección, levantamiento del proyecto,
                instalación y en los servicios posteriores que puedas requerir.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="py-5 px-0 overflow-hidden">
        <div className="row" data-aos="zoom-out-right">
          <div className="col-4 py-5 px-0">
            <div className="col-12 bg-secondary pt-5 pb-4"></div>
          </div>
          <div className="col-1 py-5 px-0">
            <div className="col-4 bg-secondary bg-opacity-75 pt-5 pb-4"></div>
          </div>
          <div className="col-7 py-5 pe-4">
            <h3 data-aos="zoom-out-up" className="fnt-lemonmilk-light">
              GALERÍA DE{" "}
              <b className="fnt-lemonmilk-bold">
                PRODUCTOS <br />
                TERMINADOS
              </b>
            </h3>
          </div>
        </div>

        <Container>

          <Swiper
          spaceBetween={10}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false
          }}
          breakpoints={{
            576: {
              slidesPerView: 1,
            },
            768:{
              slidesPerView: 2
            },
            992: {
              slidesPerView: 3
            },
            1250:{
              slidesPerView: 4
            }
          }}
          modules={[Autoplay]}
          className='galeriaSwiper'
          >
            <SwiperSlide className='galeriaItem'>
              <img src={galeryImg1} onClick={() => handleShowModal(galeryImg1)} alt="Imagen Banner 1" />
            </SwiperSlide>
            <SwiperSlide className='galeriaItem'>
              <img src={galeryImg2} onClick={() => handleShowModal(galeryImg2)} alt="Imagen Banner 2" />
            </SwiperSlide>
            <SwiperSlide className='galeriaItem'>
              <img src={galeryImg3} onClick={() => handleShowModal(galeryImg3)} alt='Imagen Banner 3' />
            </SwiperSlide>
            <SwiperSlide className='galeriaItem'>
              <img src={galeryImg4} onClick={() => handleShowModal(galeryImg4)} alt='Imagen Banner 4' />
            </SwiperSlide>
            <SwiperSlide className='galeriaItem'>
              <img src={galeryImg5} onClick={() => handleShowModal(galeryImg5)} alt='Imagen Banner 5' />
            </SwiperSlide>
            <SwiperSlide className='galeriaItem'>
              <img src={galeryImg6} onClick={() => handleShowModal(galeryImg6)} alt='Imagen Banner 6' />
            </SwiperSlide>
            <SwiperSlide className='galeriaItem'>
              <img src={galeryImg7} onClick={() => handleShowModal(galeryImg7)} alt='Imagen Banner 7' />
            </SwiperSlide>
            <SwiperSlide className='galeriaItem'>
              <img src={galeryImg8} onClick={() => handleShowModal(galeryImg8)} alt='Imagen Banner 8' />
            </SwiperSlide>
          </Swiper>


          {/* Modal para ver las imagenes de galeria */}

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <ModalBody className='p-3 position-relative'>
              {selectImage && <img src={selectImage} className='w-100' alt='Imagen Banner'/>}
                <div className='position-absolute p-3 z-2 top-0 end-0'>
                <button onClick={() => handleCloseModal()} className='btn btn-close'></button>
                </div>
            </ModalBody>
          </Modal>
          
        </Container>
      </Container>
    </div>
  );
}

export default Productos