import React, { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/esm/Container'
import { Link } from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import logo from './../images/all/logo.png'
import SendEmail from './SendEmail'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Swal from 'sweetalert2'

const URL = "http://localhost:3000/"

function Footer() {
    useEffect(() => {
        AOS.init();
        
    },[])

    const form = useRef();
    const yup = require('yup')
    const es = require('yup-es')
    yup.setLocale(es)
    
    const initialValues = {
        email: "",
        termsAndConditions: false,
    }

    const validationSchema = Yup.object().shape({
        email: Yup
            .string()
            .email("Correo invalido")
            .required("Escribe tu correo electronico"),
        termsAndConditions: Yup
            .bool()
            .oneOf([true], 'Es necesario aceptar los terminos y condiciones'),
    })

    const onSubmit = () => {
        SendEmail("template_nbjuxub",form.current);
        Swal.fire({
            title: 'Bien!',
            text: 'Se envio tu correo correctamente',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          document.querySelector("form").reset()
    }

    return (
    <div className='overflow-hidden'>
        <Container fluid className="bg-footer bg-pCenter-sCover py-5" data-aos="fade-up">
            <Container>
                <div className="row">
                    <div className='col-lg-4 border-end border-5 border-warning d-flex align-items-center justify-content-center p-5 p-lg-0' data-aos="fade-down">
                        <img alt="Bloquen" src={logo} className="img-fluid" data-aos="fade-right"/>
                    </div>
                    <div className='col-lg-4 d-flex justify-content-center align-items-center mt-3 mt-lg-0'>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-transparent">
                                <div className='d-flex flex-column align-items-center align-items-lg-start justify-content-center'>
                                    <Link data-aos="fade-right" to="/" className='btn btn-outline-light border-0 mt-1 rounded-0 '>INICIO</Link>
                                    <Link data-aos="fade-right" to="/productos/" className='btn btn-outline-light border-0 mt-1 rounded-0 '>PRODUCTOS</Link>
                                    <Link data-aos="fade-right" to="/tu-mundo/" className='btn btn-outline-light border-0 mt-1 rounded-0 '>TU MUNDO</Link>
                                    <Link data-aos="fade-right" to="/desarrolladores/" className='btn btn-outline-light border-0 mt-1 rounded-0 '>DESARROLLADORES</Link>
                                    <Link data-aos="fade-right" to="/contacto/" className='btn btn-outline-light border-0 mt-1 rounded-0 '>CONTACTO</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='col-lg-4 mt-5 mt-lg-0 text-center text-lg-start'>
                        <a href="https://www.facebook.com/Bloken-100969762721498/" target="_blank" data-aos="zoom-out" className='btn btn-outline-light rounded-0 border-0 m-0 d-inline fs-3 p-1'><i className='bx bxl-facebook'></i></a>
                        <a href="https://instagram.com/blokenmexico?igshid=Y2ZmNzg0YzQ=" target="_blank" data-aos="zoom-out" className='btn btn-outline-light rounded-0 border-0 m-0 d-inline fs-3 p-1'><i className='bx bxl-instagram'></i></a>
                        <a href="https://www.linkedin.com/" target="_blank" data-aos="zoom-out" className='btn btn-outline-light rounded-0 border-0 m-0 d-inline fs-3 p-1'><i className='bx bxl-linkedin'></i></a><br/>
                        <a data-aos="fade-left" href='tel:3316567598' target="_blank" className='btn btn-outline-light rounded-0 border-0 m-0 d-inline-block fs-5 fnt-helvetica-light fw-bold my-3'>Tel. (33) 16567598</a><br/>
                        <a data-aos="fade-left" href='mailto:hola@bloken.com' target="_blank" className='btn btn-outline-light rounded-0 border-0 m-0 d-inline fs-5 fnt-helvetica-light fw-bold'>Correo: hola@bloken.com</a>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                            <Form ref={form} className='d-flex align-items-center align-items-lg-start justify-content-center flex-column mt-4'>
                                <div data-aos="fade-down" className='d-flex align-items-center justify-content-center rounded-0 bg-white bg-opacity-75 p-1'>
                                    <Field className="form-control rounded-0 bg-transparent border-0" name="email" placeholder="Correo" />
                                    <button className="btn bg-secondary bg-opacity-75 rounded-0 text-white px-4" type="submit">Suscribirse</button>
                                </div>
                                <div className='text-danger py-2'>
                                    <ErrorMessage name="email" component="span" /> <br/>
                                </div>
                                <label data-aos="fade-down" className='text-white'>
                                    <Field type="checkbox" name="termsAndConditions"  className="me-2"/>
                                    He leído y acepto las <span className='text-warning'>Politicas de Privacidad</span>
                                </label>
                                <div className='text-danger pt-2'>
                                    <ErrorMessage name="termsAndConditions" component="span" /> <br/>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </Container>
        </Container>
        <Container fluid>
            <div className='row bg-secondary text-center py-3'>
                <h6 className='m-0 text-light'>Copyright <i className='bx bx-copyright'></i> 2022 Bloken Innovación Inmobiliario</h6>
            </div>
        </Container>
    </div>
  )
}

export default Footer