import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Main from './pages/Main';
import Footer from './includes/Footer';
import Container from 'react-bootstrap/Container';
import Navbar_ from './includes/Navbar_';
import Cualidades from './pages/Cualidades';
import Productos from './pages/Productos';
import Desarrollo from './pages/Desarrollo';
import Contacto from './pages/Contacto';
import ProductosGallery from './pages/ProductosGallery';

import whatsapp from "./images/all/whatsapp.png"

function App() {
  return (
    <Container fluid className="position-relative p-0">
      <BrowserRouter>
        <Navbar_/>
        <Routes>
          <Route path="/" element={<Main/>}></Route>
          <Route path="/productos/" element={<Productos/>}></Route>
          <Route path="/productos/:id" element={<ProductosGallery/>}></Route>
          <Route path="/tu-mundo/" element={<Cualidades/>}></Route>
          <Route path="/desarrolladores/" element={<Desarrollo/>}></Route>
          <Route path="/contacto/" element={<Contacto/>}></Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
      <a className="iconWhatsapp scale transitions shadow" href="https://wa.me/3311709253" target="_blank" style={{zIndex: "10"}}>
        <img src={whatsapp} className="img-fluid" alt="whatsapp" />
      </a>
    </Container>
  );
}

export default App;
