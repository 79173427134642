import emailjs from '@emailjs/browser';

export default function SendEmail(template, data) {
    emailjs.sendForm('service_bi6s20j', template, data, 'A9aXDl7n6jwpvTt4G')
        .then((result) => {
            console.log(result.text);
            return result
        }, (error) => {
            console.log(error.text);
        });
}
