import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';

import logo from './../images/all/logo.png'

function Navbar_() {
    const [isOpen, setIsOpen] = useState(false)
    const [isResponsive, setIsResposive] = useState(false)

useEffect(() => {
    AOS.init();
}, [])

const handleWidth=() =>{
    const bodyWidth = document.querySelector("body")
    if(bodyWidth.offsetWidth <= 991) return setIsResposive(true)
    return setIsResposive(false)
}

window.onresize = (e) => {
    handleWidth()
    AOS.refresh();
};

const handleOpenNavbar = () => {
    const navbar = document.getElementById("navbar")
    if(isOpen){
        setIsOpen(false)
        navbar.classList.add("bg-opacity-75")
    }else{
        setIsOpen(true)
        navbar.classList.remove("bg-opacity-75")
    }
}



return (
    <Navbar expanded={isOpen} id="navbar" data-aos="zoom-out" bg="dark" expand="lg" className="position-absolute top-0 start-0 w-100 mt-5 p-0 bg-opacity-75 navbar-dark" style={{zIndex: "10"}}>
        <Container>
            <Link to="/"><img alt="Bloquen" className='my-3 my-lg-0' src={logo} style={{height: "4rem"}}/></Link>
            <Navbar.Toggle onClick={() => handleOpenNavbar()} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className='transitions' id="basic-navbar-nav">
                <Nav className="ms-auto h-100 py-4 py-lg-0 ">
                    <Link onClick={isResponsive ? () => handleOpenNavbar() : null} id="inicio" className="btn nav-text border-0 mx-1 fs-6 fnt-helvetica-lt-std-light transitions scale rounded-0 d-flex align-items-center" to="/">Inicio</Link>
                    <Link onClick={isResponsive ? () => handleOpenNavbar() : null} id="productos" className="btn nav-text border-0 mx-1 fs-6 fnt-helvetica-lt-std-light transitions scale rounded-0 d-flex align-items-center" to="/productos/">Productos</Link>
                    <Link onClick={isResponsive ? () => handleOpenNavbar() : null} id="cualdiades" className='btn nav-text border-0 mx-1 fs-6 fnt-helvetica-lt-std-light transitions scale rounded-0 d-flex align-items-center' to="/tu-mundo/" >Tu Mundo</Link>
                    <Link onClick={isResponsive ? () => handleOpenNavbar() : null} id="arq" className="btn nav-text border-0 mx-1 fs-6 fnt-helvetica-lt-std-light transitions scale rounded-0 d-flex align-items-center" to="/desarrolladores/">Desarrolladores</Link>
                    <Link onClick={isResponsive ? () => handleOpenNavbar() : null} id="contacto" className="btn nav-text border-0 mx-1 fs-6 fnt-helvetica-lt-std-light transitions scale rounded-0 d-flex align-items-center" to="/contacto/">Contacto</Link>
                    <div className='d-flex justify-content-center align-items-center'>
                        <a className="btn nav-text border-0 mx-1 fs-4 fnt-helvetica-lt-std-light transitions scale py-lg-4 m-0 rounded-0" href="https://www.facebook.com/Bloken-100969762721498/" target="_blank"><i className='bx bxl-facebook'></i></a>
                        <a className="btn nav-text border-0 mx-1 fs-4 fnt-helvetica-lt-std-light transitions scale py-lg-4 m-0 rounded-0" href="https://instagram.com/blokenmexico?igshid=Y2ZmNzg0YzQ=" target="_blank"><i className='bx bxl-instagram'></i></a>
                        <a className="btn nav-text border-0 mx-1 fs-4 fnt-helvetica-lt-std-light transitions scale py-lg-4 m-0 rounded-0" href="mailto:hola@bloken.mx" target="_blank"><i className='bx bx-envelope'></i></a>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    );
}

export default Navbar_

