import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import marca1 from "./../images/cualidades/marca1.png"
import marca2 from "./../images/cualidades/marca2.png"
import marca3 from "./../images/cualidades/marca3.png"
import marca4 from "./../images/cualidades/marca4.png"
import marca5 from "./../images/cualidades/marca5.png"

// MATERIALES ARAUCO
import material1 from "./../images/arqcons/arauco/cerezo.jpg"
import material2 from "./../images/arqcons/arauco/gris.jpg"
import material3 from "./../images/arqcons/arauco/laricina-siberia.jpg"
import material4 from "./../images/arqcons/arauco/metropolitan.jpg"
import material5 from "./../images/arqcons/arauco/negro.jpg"
import material6 from "./../images/arqcons/arauco/nogal-britanico.jpg"
import material7 from "./../images/arqcons/arauco/nogal-neo.jpg"
import material8 from "./../images/arqcons/arauco/nogal-urbano.jpg"
import material9 from "./../images/arqcons/arauco/rioja.jpg"
import material10 from "./../images/arqcons/arauco/rojo-colonial.jpg"


// MATERIALES EGGER
import gladstoneBlanco from "./../images/arqcons/egger/gladstone-blanco.jpg"
import gladstoneLava from "./../images/arqcons/egger/gladstone-lava.jpg"
import gladstoneTabaco from "./../images/arqcons/egger/gladstone-tabaco.jpg"
import halifaxBlanco from "./../images/arqcons/egger/halifax-blanco.jpg"
import halifaxNatural from "./../images/arqcons/egger/halifax-natural.jpg"
import halifaxTabaco from "./../images/arqcons/egger/halifax-tabaco.jpg"
import halifaxZinc from "./../images/arqcons/egger/halifax-zinc.jpg"
import oolmoTossiniCrudo from "./../images/arqcons/egger/oolmo-tossini-crudo.jpg"
import shermanCognac from "./../images/arqcons/egger/sherman-cognac.jpg"

// MATERIALES HETTICH
import compasParaPuerta from "./../images/arqcons/Hettich/compas-para-puerta.jpg"
import herraje1 from "./../images/arqcons/Hettich/Herraje-1.jpg"
import herraje2 from "./../images/arqcons/Hettich/Herraje-2.jpg"
import herraje3 from "./../images/arqcons/Hettich/Herraje-3.jpg"
import herrajeElevable from "./../images/arqcons/Hettich/herraje-elevable.jpg"

// MATERIALES VESTO
import alaska from "./../images/arqcons/vesto/alaska.jpg"
import aserradoNordico from "./../images/arqcons/vesto/aserrado-nordico.jpg"
import cendraEscandinavo from "./../images/arqcons/vesto/cendra-escandinavo.jpg"
import nativa from "./../images/arqcons/vesto/nativa.jpg"
import nogalTerracota from "./../images/arqcons/vesto/nogal-terracota.jpg"
import riviera from "./../images/arqcons/vesto/riviera.jpg"
import robleAmericano from "./../images/arqcons/vesto/roble-americano.jpg"
import robleProvenzal from "./../images/arqcons/vesto/roble-provenzal.jpg"
import tekaArtico from "./../images/arqcons/vesto/teka-artico.jpg"
import toscana from "./../images/arqcons/vesto/toscana.jpg"

// MATERIALES BLUM
import bisagras from "./../images/arqcons/Blum/bisagras.jpg"
import box from "./../images/arqcons/Blum/box.jpg"
import divisiones from "./../images/arqcons/Blum/divisiones.jpg"
import guias from "./../images/arqcons/Blum/guias.jpg"
import herraje from "./../images/arqcons/Blum/herraje.jpg"


import icon1 from "./../images/arqcons/icon1.png"
import icon2 from "./../images/arqcons/icon2.png"
import icon3 from "./../images/arqcons/icon3.png"
import icon4 from "./../images/arqcons/icon4.png"

import innovacion from "./../images/arqcons/innovacion.jpg"
import rectangulo from "./../images/arqcons/rectangulo.png"

import pdf from "./../pdf/bloken.pdf"

function Desarrollo() {
  const [newMarca, setNewMarca] = useState([])
  
  const marcaArauco ={
    desc: "La innovación nos mueve y desafía a combinar ideas, tecnología y creatividad para desarrollar nuevas y mejores soluciones para tus necesidades",
    link: "https://mx.arauco.com/c/products/ct-melamina/br-melamina",
    materiales: [
      {
        materialesDescripcion: "Cerezo",
        materialesImage: material1
      },
      {
        materialesDescripcion: "Gris",
        materialesImage: material2
      },
      {
        materialesDescripcion: "Laricina Siberia",
        materialesImage: material3
      },
      {
        materialesDescripcion: "Metropolitan",
        materialesImage: material4
      },
      {
        materialesDescripcion: "Negro",
        materialesImage: material5
      },
      {
        materialesDescripcion: "Nogal Británico",
        materialesImage: material6
      },
      {
        materialesDescripcion: "Nogal Neo",
        materialesImage: material7
      },
      {
        materialesDescripcion: "Nogal Urbano",
        materialesImage: material8
      },
      {
        materialesDescripcion: "Rioja",
        materialesImage: material9
      },
      {
        materialesDescripcion: "Rojo Colonial",
        materialesImage: material10
      },
    ]
  }

  const marcaEgger ={
    desc: "Impresionandote a través de la calidad y el estilo.",
    link: "https://www.egger.com/shop/es_MX/interior/decors",
    materiales: [
      {
        materialesDescripcion: "Gladstone Blanco",
        materialesImage: gladstoneBlanco
      },
      {
        materialesDescripcion: "Gladstone Lava",
        materialesImage: gladstoneLava
      },
      {
        materialesDescripcion: "Gladstone Tabaco",
        materialesImage: gladstoneTabaco
      },
      {
        materialesDescripcion: "Halifax Blanco",
        materialesImage: halifaxBlanco
      },
      {
        materialesDescripcion: "Halifax Natural",
        materialesImage: halifaxNatural
      },
      {
        materialesDescripcion: "Halifax Tabaco",
        materialesImage: halifaxTabaco
      },
      {
        materialesDescripcion: "Halifax Zinc",
        materialesImage: halifaxZinc
      },
      {
        materialesDescripcion: "Oolmo Tossini Crudo",
        materialesImage: oolmoTossiniCrudo
      },
      {
        materialesDescripcion: "Sherman Cognac",
        materialesImage: shermanCognac
      },
    ]
  }
  const marcaHettich ={
    desc: "Conseguimos la perfecta combinación entre tecnología inteligente, funcionalidad y sieño.",
    link: "https://web.hettich.com/es-mx/productos-y-eshop/bisagras#/",
    materiales: [
        {
          materialesDescripcion: "Compas Para Puerta",
          materialesImage: compasParaPuerta
        },
        {
          materialesDescripcion: "Herraje 1",
          materialesImage: herraje1
        },
        {
          materialesDescripcion: "Herraje 2",
          materialesImage: herraje2
        },
        {
          materialesDescripcion: "Herraje 3",
          materialesImage: herraje3
        },
        {
          materialesDescripcion: "Herraje Elevable",
          materialesImage: herrajeElevable
        },
      ]
}
const marcaVesto ={
    desc: "Tendencias y calidad Europea para tu hogar.",
    link: "https://mx.arauco.com/c/products/ct-melamina/br-vesto",
    materiales: [
        {
          materialesDescripcion: "Alaska",
          materialesImage: alaska
        },
        {
          materialesDescripcion: "Aserrado Nórdico",
          materialesImage: aserradoNordico
        },
        {
          materialesDescripcion: "Cendra Escandinavo",
          materialesImage: cendraEscandinavo
        },
        {
          materialesDescripcion: "Nativa",
          materialesImage: nativa
        },
        {
          materialesDescripcion: "Nogal Terracota",
          materialesImage: nogalTerracota
        },
        {
          materialesDescripcion: "Riviera",
          materialesImage: riviera
        },
        {
          materialesDescripcion: "Roble Americano",
          materialesImage: robleAmericano
        },
        {
          materialesDescripcion: "Roble Provenzal",
          materialesImage: robleProvenzal
        },
        {
          materialesDescripcion: "Teka Ártico",
          materialesImage: tekaArtico
        },
        {
          materialesDescripcion: "Toscana",
          materialesImage: toscana
        },
      ]
}
const marcaBlum ={
    desc: "Haciendo realidad todas las ideas creativas para tus muebles.",
    link: "https://www.blum.com/mx/es/products/product-world/",
    materiales: [
        {
          materialesDescripcion: "Bisagras",
          materialesImage: bisagras
        },
        {
          materialesDescripcion: "Box",
          materialesImage: box
        },
        {
          materialesDescripcion: "Divisiones",
          materialesImage: divisiones
        },
        {
          materialesDescripcion: "Guias",
          materialesImage: guias
        },
        {
          materialesDescripcion: "Herraje",
          materialesImage: herraje
        },
      ]
}

  useEffect(() => {
    window.scrollTo(0, 0)
    setNewMarca(marcaArauco)
    // AOS.init();
  }, [])

  const eventNewMarca = (marca) => {
    setNewMarca(marca)
    AOS.refreshHard()
  }
  window.onresize = (e) => {
    AOS.refreshHard()
  };

  return (
    <div className="overflow-hidden">
      <Container fluid className="bg-pCenter-sCover bg-arqcons min-vh-100 d-flex align-items-end pb-5" data-aos="zoom-out-up">
        <Container>
          <Row>
            <Col>
              <h1 className='text-white  fnt-lemonmilk-light'>
                BRINDAMOS SOLUCIONES <b>INTEGRALES</b>
              </h1>
              <p className='text-white fs-3 d-inline-block'>
                Hablando de mobiliario residencial respaldamos tus desarrollos inmobiliarios.
                <div className='border-white border-bottom border-5 mt-2 w-100'></div>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='positon-relative mt-5' data-aos="fade-up">
        <div className='row'>
          <div className='col-12 p-0 position-relative'>
            <div className="ratio ratio-16x9 position-relative overflow-hidden">
              <div>
                <iframe loading="lazy" title="bloken" className='video' src="https://www.youtube.com/embed/u9Q3XZaDkn0?playlist=u9Q3XZaDkn0&amp;rel=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;controls=0&amp;enablejsapi=1&amp;showinfo=0&amp;modestbranding=1"></iframe>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <Row>
          <Col className="d-flex jstify-content-center">
            <a className='btn btn-outline-secondary rounded-0 p-4 mt-5 mx-auto d-inline-block' href={pdf} download="Bloken Brochure">Descargar Brochure</a>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5">
        <Row data-aos="zoom-out-right">
            <Col lg={4} className='col-4 py-5 px-0'>
                <div className='col-12 bg-secondary pt-5 pb-4'></div>
            </Col>
            <Col lg={1} className='col-1 py-5 px-0'>
                <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
            </Col>
            <Col className='py-5 d-flex align-items-center'>
                <h1 data-aos="zoom-out-up" className="fnt-lemonmilk-light text-justify fw-bold">PROCESOS</h1>
            </Col>
        </Row>
      </Container>
      <Container fluid className="bg-pCenter-sCover bg-arqcons-1 py-5" data-aos="zoom-out-up">
        <Container>
          <Row>
            <Col data-aos="zoom-out-up" lg={3} className="text-center py-5">
              <img loading="lazy" src={icon1} width="35%" />
              <p className='text-white mt-3 fnt-helvetica-lt-std-light'><b className='fnt-helvetica-bold'>1.</b> Medición y personalización</p>
            </Col>
            <Col data-aos="zoom-out-down" lg={3} className="text-center py-5">
              <img loading="lazy" src={icon2} width="35%" />
              <p className='text-white mt-3 fnt-helvetica-lt-std-light'><b className='fnt-helvetica-bold'>2.</b> Diseño con software especializado</p>
            </Col>
            <Col data-aos="zoom-out-down" lg={3} className="text-center py-5">
              <img loading="lazy" src={icon3} width="35%" />
              <p className='text-white mt-3 fnt-helvetica-lt-std-light'><b className='fnt-helvetica-bold'>3.</b> Manufactura especializada</p>
            </Col>
            <Col data-aos="zoom-out-down" lg={3} className="text-center py-5">
              <img loading="lazy" src={icon4} width="35%" />
              <p className='text-white mt-3 fnt-helvetica-lt-std-light'><b className='fnt-helvetica-bold'>4.</b> Instalación inteligente</p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="mt-5">
        <Row data-aos="zoom-out-right">
            <Col lg={4} className='col-4 py-5 px-0'>
                <div className='col-12 bg-secondary pt-5 pb-4'></div>
            </Col>
            <Col lg={1} className='col-1 py-5 px-0'>
                <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
            </Col>
            <Col className='py-5 d-flex align-items-center'>
                <h1 data-aos="zoom-out-up" className="fnt-lemonmilk-light fw-bold">LAS MARCAS QUE NOS RESPALDAN</h1>
            </Col>
        </Row>
      </Container>
      <Container fluid className='mt-5 bg-secondary bg-opacity-25 py-5' data-aos="zoom-out-up">
        <Container>
          <Row className='d-flex align-items-end justify-content-around py-5'>
            <Col data-aos="zoom-out-up" lg={2} className='text-center'>
              <img loading="lazy" alt="arauco" src={marca1} className="img-fluid" /><br></br>
              <a href="#marcasCertificadas" className='py-2 px-3 border-0 btn-ver-mas d-inline-block mt-2' onClick={() => eventNewMarca(marcaArauco)}>Ver matreriales</a>
            </Col>
            <Col data-aos="zoom-out-down" lg={2} className='text-center mt-5 mt-lg-0'>
              <img loading="lazy" alt="e egger" src={marca2} className="img-fluid" /><br></br>
              <a href="#marcasCertificadas" className='py-2 px-3 border-0 btn-ver-mas d-inline-block mt-2' onClick={() => eventNewMarca(marcaEgger)}>Ver matreriales</a>
            </Col>
            <Col data-aos="zoom-out-up" lg={2} className='text-center mt-5 mt-lg-0'>
              <img loading="lazy" alt="hettich" src={marca3} className="img-fluid" /><br></br>
              <a href="#marcasCertificadas" className='py-2 px-3 border-0 btn-ver-mas d-inline-block mt-2' onClick={() => eventNewMarca(marcaHettich)}>Ver matreriales</a>
            </Col>
            <Col data-aos="zoom-out-down" lg={2} className='text-center mt-5 mt-lg-0'>
              <img loading="lazy" alt="mode" src={marca4} className="img-fluid" /><br></br>
              <a href="#marcasCertificadas" className='py-2 px-3 border-0 btn-ver-mas d-inline-block mt-2' onClick={() => eventNewMarca(marcaVesto)}>Ver matreriales</a>
            </Col>
            <Col data-aos="zoom-out-up" lg={2} className='text-center mt-5 mt-lg-0'>
              <img loading="lazy" alt="blum" src={marca5} className="img-fluid" /><br></br>
              <a href="#marcasCertificadas" className='py-2 px-3 border-0 btn-ver-mas d-inline-block mt-2' onClick={() => eventNewMarca(marcaBlum)}>Ver matreriales</a>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='my-5'>
        <Row>
          <Col lg={12}  id="marcasCertificadas" >
            {newMarca.desc && <h2 data-aos="zoom-out-down" className='text-center fnt-helvetica-lt-std-light'>{newMarca.desc}</h2>}
            <Swiper
            className=''
              modules={[Navigation, Pagination]}
              spaceBetween={0}
              navigation
              pagination={{ clickable: true }}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  height: 400,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  height: 400,
                  slidesPerView: 2,
                },
                1199: {
                  height: 400,
                  slidesPerView: 4,
                },
              }}
              >
              { newMarca.materiales &&
                newMarca.materiales.map((val, index) => {
                  return (
                    <SwiperSlide key={index} className="position-relative">
                      <img loading="lazy"
                      className="d-block w-100 p-5 h-100"
                      src={val.materialesImage}
                      alt={val.materialesDescripcion}
                      />
                      <h4 className='position-absolute bottom-0 start-50 translate-middle-x text-center text-white mb-5 pb-3'>{val.materialesDescripcion}</h4>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
            <div className='d-flex justify-content-center mt-5'>
              {newMarca.link && <a data-aos="zoom-out-right" href={newMarca.link} target="_blank" download className='btn btn-outline-secondary rounded-0 d-inline-block border-4 border border-secondary'>Ver más</a>}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="position-relative px-4">
          <Col lg={7} style={{zIndex: 2}}>
            <img data-aos="zoom-out" loading="lazy" src={innovacion} alt='innovacion' className='img-fluid p-4' />
          </Col>
          <Col lg={5} className="p-4 d-lg-none">
            <h1 data-aos="zoom-out-up" className=' fnt-helvetica-bold fw-bold m-0 mb-4'>
              INNOVACIÓN
              <div data-aos="zoom-out-up" className='border-5 border-bottom border-warning w-25 mt-3'></div>
              </h1>
            <p data-aos="zoom-out-up" className='mt-3 fnt-helvetica-lt-std-light mb-0 text-justify'>Poseemos la experiencia y profesionalismo en áreas como la decoración, el interiorismo, diseño e innovación para la creacioón de nuevos modelos.</p>
          </Col>
          <Col data-aos="zoom-out-up" lg={12} className='d-none d-lg-flex py-5 position-absolute start-0 top-50 translate-middle-y justify-content-end p-5' style={{zIndex: 1}}>
            <Col lg={5} className="p-4">
              <h1 data-aos="zoom-out-up" className=' fnt-helvetica-bold fw-bold m-0 mb-4'>
                INNOVACIÓN
                <div data-aos="zoom-out-up" className='border-5 border-bottom border-warning w-25 mt-3'></div>
                </h1>
              <p data-aos="zoom-out-up" className='mt-3 fnt-helvetica-lt-std-light mb-0 text-justify'>Poseemos la experiencia y profesionalismo en áreas como la decoración, el interiorismo, diseño e innovación para la creacioón de nuevos modelos.</p>
            </Col>
            <img data-aos="zoom-out-up" loading="lazy" className='position-absolute top-0 start-0 w-100 h-100' src={rectangulo} alt="rectangulo" />
          </Col>
          <Col lg={5}></Col>
        </Row>
      </Container>
      <Container fluid className="my-5 pb-5">
        <Row data-aos="zoom-out-right">
            <Col lg={4} className='col-4 pt-5 px-0'>
                <div className='col-12 bg-secondary pt-5 pb-4'></div>
            </Col>
            <Col lg={1} className='col-1 pt-5 px-0'>
                <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
            </Col>
            <Col xs={7} lg={7} className='pt-lg-5 py-4 d-flex align-items-start flex-column justify-content-start overflow-hidden'>
                <h3 className="fnt-lemonmilk-light fw-bold mb-5 mb-lg-0 mt-3">PLATIQUEMOS DE TU PROYECTO</h3>
            </Col>
            <Col lg={7} className="ms-auto">
              <p data-aos="zoom-out-up" className='fs-5 fnt-helvetica-lt-std-light my-lg-3 text-justify'>Entendemos perfectamente bien el compromiso que conlleva hacernos parte de tu proyecto, sabemos que lo que necesitas es un aliado experto en diseño, y con una infraestructura que te brinde confianza y seguridad en el diseño y fabricación, Bloken está para cumplir todas tus expectativas.</p>
            </Col>
        </Row>
        <Row data-aos="zoom-out-up" className='p-5 bg-pCenter-sCover bg-arqcons-2-blur min-vh-75'>
          <Col data-aos="zoom-out-up" lg={12} className="bg-pCenter-sCover bg-arqcons-2 min-vh-75 shadow-lg"></Col>
        </Row>
        <Row className='p-5 bg-pCenter-sCover bg-arqcons-3 py-5' data-aos="zoom-out-up">
          <Col lg={12} className="d-flex align-items-center justify-content-center flex-wrap">
            <h1 className="fnt-helvetica-bold m-0 text-light text-center" data-aos="zoom-out-right">¡Cotiza tu proyecto aquí!</h1>
            <Link to="/contacto/" className="btn btn-outline-light rounded-0 fs-3 ms-lg-5 border border-4 border-white mt-4 mt-lg-0" data-aos="zoom-out-left">Contáctanos</Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Desarrollo