import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Carousel from 'react-bootstrap/Carousel'

import galeria1 from "./../images/main/galeria/3.jpg"
import galeria2 from "./../images/main/galeria/1.jpeg"
import galeria3 from "./../images/main/galeria/2.jpg"
import galeria4 from "./../images/main/galeria/4.jpeg"
import rectangulo from "./../images/arqcons/rectangulo.png"
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Main() {

    const initialValues = [
        {
            descripcion: "Casa Fairway ",
            adress: "Condominio Santa Anita, Tlajomulco de Zuñiga, Jalisco "
        },
        {
            descripcion: "Casa Sandoval",
            adress: "Fracc Santa Rita, Zapopan, Jalisco"
        },
        {
            descripcion: "Casa Merino",
            adress: "Valle Real, Zapopan, Jalisco"
        },
        {
            descripcion: "Casa Turin",
            adress: "Fracc Providencia, Guadalajara, Jalisco"
        },
    ]
    const [infoCarousel, setInfoCarousel] = useState(initialValues[0])

    const galeria = [
        {
            imagen: galeria1
        },
        {
            imagen: galeria3
        },
        {
            imagen: galeria2
        },
        {
            imagen: galeria4
        },
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init();
    }, [])

    const slide = (data) => {
        setInfoCarousel(initialValues[data])
    }
    window.onresize = (e) => {
        AOS.refresh()
      };
    
  return (
    <div className='overflow-hidden'>
        <Container fluid className='min-vh-100 bg-header bg-pCenter-sCover d-flex align-items-end px-4 px-lg-0' data-aos="fade-down">
            <Container>
                <div className='row'>
                    <di className='col-8 p-0'>
                        <div className='row'>
                            <div className='col-lg-9 border-size-top border-size-end border-size-start border-white py-4 px-2 d-flex justify-content-center'>
                                <div>
                                    <h1 className='text-white fnt-lemonmilk-light fw-light'>PERFECCIÓN EN <b className='fnt-lemonmilk-bold fw-bold'>CADA <br/> DETALLE</b></h1>
                                </div>
                            </div>
                        </div>
                    </di>
                    <di className='col-12 col-lg-8 mb-5 p-0'>
                        <div className='row'>
                            <div className='col-6 col-lg-8 border-size-end border-size-top border-white pt-5 d-flex justify-content-end pe-0'>
                                <h4 className='bg-white mb-0 mt-4 p-2 fnt-helvetica-lt-std-light'>Diseño cálido y equilibrado</h4>
                            </div>
                            <div className='col-6 col-lg-3 border-size-top border-warning p-0'>
                                <div className='p-2 border-size-end border-size-start border-size-bottom border-warning ms-lg-4'>
                                    <Link to="contacto/" className='text-decoration-none '>
                                        <h5 className='text-light text-center fnt-helvetica-lt-std-light fw-bold transitions scale m-0'>Contáctanos</h5>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </di>
                </div>
            </Container>
        </Container>
        <Container fluid className='min-vh-75 d-flex align-items-center' style={{background: "#000"}}  data-aos="fade-up">
            <Container>
                <div className='row py-5'>
                    <div className='col-lg-6 d-flex align-items-center justify-content-center order-2 order-lg-1 mt-5 mt-lg-0'>
                        <p className="text-white fnt-helvetica-lt-std-light text-justify " data-aos="zoom-out">
                            Somos una empresa orgullosamente mexicana que fue creada con la intención de llenar el espacio en la demanda  de inmobiliario, enfocándolo a arquitectos, desarrolladores inmobiliarios, interioristas, así como a la industria en general. Nuestros proyectos aparte de dar vida a los espacios, son capaces de combinar las últimas tendencias con elementos funcionales.
                        </p>
                    </div>
                    <div className='col-lg-6 d-flex align-items-center justify-content-center order-1 order-lg-2'  data-aos="fade-left">
                        <h1 className='text-white border-size-end d-inline-block border-warning position-relative py-5 pe-5 display-4 '>
                            <div className='border-size-start border-size-top border-warning pt-4 w-50 position-absolute end-0 top-0'></div>
                            <span className='fnt-lemonmilk-light fw-light'>CREAMOS</span> <br/>
                            <b className="fnt-lemonmilk-bold">EXPERIENCIAS</b> <br/>
                            <b className="fnt-lemonmilk-bold">INOLVIDABLES</b> <br/>
                            <div className='border-size-bottom border-warning w-25 position-absolute end-0 bottom-0'></div>
                        </h1>
                    </div>
                </div>
            </Container>
        </Container>
        <Container className='positon-relative mt-5' data-aos="fade-up">
            <div className='row'>
                <div className='col-12 p-0 position-relative'>
                    <div className="ratio ratio-16x9 position-relative overflow-hidden">
                        <div>
                            <iframe loading="lazy" title="bloken" className='video' src="https://www.youtube.com/embed/Qupfu9BaFBk?playlist=Qupfu9BaFBk&amp;rel=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;controls=0&amp;enablejsapi=1&amp;showinfo=0&amp;modestbranding=1"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <Container fluid className="my-5">
            <div className='row'  data-aos="fade-right">
                <div className='col-4 py-5 px-0'>
                    <div className='col-12 bg-secondary pt-5 pb-4'></div>
                </div>
                <div className='col-1 py-5 px-0'>
                    <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
                </div>
                <div className='col-7 py-5 pe-4'>
                    <h1  data-aos="zoom-out" className='fnt-lemonmilk-light'>DISEÑO <b className='fnt-lemonmilk-bold'>EXCLUSIVO</b></h1>
                </div>
                <div className='col-lg-7 ms-auto'>
                    <p data-aos="zoom-out" className='fnt-helvetica-lt-std-light fs-5 mt-3'>Disponemos de los recursos necesarios para cumplir con las expectativas en calidad, tiempo y presupuesto.</p>
                    <Link data-aos="zoom-out" to="/productos/" className='btn btn-outline-secondary d-inline-block rounded-0'>Ver Productos</Link>
                </div>
            </div>
        </Container>
        <Container fluid className="bg-pCenter-sCover bg-cocina py-5" data-aos="fade-up">
            <div className='row' data-aos="fade-right">
                <div className='col-4 py-5 px-0'>
                    <div className='col-12 bg-secondary pt-5 pb-4'></div>
                </div>
                <div className='col-1 py-5 px-0'>
                    <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
                </div>
                <div className='col-7 py-5 pe-4'>
                    <h1 data-aos="zoom-out" className='fnt-lemonmilk-light'>CONOCE NUESTROS <b className='fnt-lemonmilk-bold'>ÚLTIMOS <br/>PROYECTOS</b></h1>
                </div>
            </div>
            <Container>
                <div className="row position-relative px-lg-4">
                    <div className='col-12 py-5 position-absolute start-0 top-50 translate-middle-y d-none d-lg-block' style={{zIndex: 1}}>
                        <div className="col-5">
                            <h1 data-aos="zoom-out" className='text-center fnt-helvetica-bold fw-bold m-0'>{infoCarousel.descripcion}</h1>
                            <p data-aos="zoom-out" className='text-end mt-3 fs-5 fnt-helvetica-lt-std-light mb-0'>{infoCarousel.adress}</p>
                        </div>
                        <img loading="lazy" className='position-absolute top-0 start-0 w-100 h-100 ' src={rectangulo} alt="rectangulo" />
                    </div>
                    <div className="col-lg-5">
                        <div className="col-12 d-lg-none">
                            <h1 data-aos="zoom-out" className='text-center fnt-helvetica-bold fw-bold m-0'>{infoCarousel.descripcion}</h1>
                            <p data-aos="zoom-out" className='text-end mt-3 fs-5 fnt-helvetica-lt-std-light mb-0'>{infoCarousel.adress}</p>
                        </div>
                    </div>
                    <div className='col-lg-7 mt-4 mt-lg-0 p-lg-4' style={{zIndex: 2}} data-aos="zoom-out">
                        {/* <img loading="lazy" src={cocina} alt='cocina' className='img-fluid p-lg-4' /> */}
                        <Carousel onSlide={slide}>
                            {
                                galeria.map((val, index) => {
                                    return (
                                        <Carousel.Item  key={index} className="h-100">
                                            <img loading="lazy"
                                            style={{height: "70vh"}}
                                            className="w-100 object-fit-cover"
                                            src={val.imagen}
                                            alt="galeria cocinas"
                                            />
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </Container>
        </Container>
    </div>
  )
}

export default Main