import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageGallery from 'react-image-gallery';
import Masonry from 'react-masonry-css'

import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Modal from 'react-bootstrap/Modal';

import header1 from"./../images/productos/banner/cocinas.jpg"
import header2 from"./../images/productos/banner/area.jpg"
import header3 from"./../images/productos/banner/centro.jpg"
import header4 from"./../images/productos/banner/proyectos.jpg"


// GALERIA COCINA
import cocina1 from "./../images/productos/galeriaIMG/Cocinas/galeria1.png"
import cocina2 from "./../images/productos/galeriaIMG/Cocinas/galeria2.png"
import cocina3 from "./../images/productos/galeriaIMG/Cocinas/galeria3.png"
import cocina4 from "./../images/productos/galeriaIMG/Cocinas/galeria4.png"
import cocina5 from "./../images/productos/galeriaIMG/Cocinas/galeria5.png"
import cocina6 from "./../images/productos/galeriaIMG/Cocinas/galeria6.png"

// GALERIA CLOSETS
import closet1 from "./../images/productos/galeriaIMG/Closets/img1.jpg"
import closet2 from "./../images/productos/galeriaIMG/Closets/img2.jpg"
import closet3 from "./../images/productos/galeriaIMG/Closets/img3.jpg"
import closet4 from "./../images/productos/galeriaIMG/Closets/img4.jpg"
import closet5 from "./../images/productos/galeriaIMG/Closets/img5.jpg"
import closet6 from "./../images/productos/galeriaIMG/Closets/img6.jpg"
import closet7 from "./../images/productos/galeriaIMG/Closets/img7.jpg"
import closet8 from "./../images/productos/galeriaIMG/Closets/img8.jpg"
import closet9 from "./../images/productos/galeriaIMG/Closets/img9.jpg"
import closet10 from "./../images/productos/galeriaIMG/Closets/img10.jpg"
import closet11 from "./../images/productos/galeriaIMG/Closets/img11.jpg"
import closet12 from "./../images/productos/galeriaIMG/Closets/img12.jpg"
import closet13 from "./../images/productos/galeriaIMG/Closets/img13.jpg"
import closet14 from "./../images/productos/galeriaIMG/Closets/img14.jpg"
import closet15 from "./../images/productos/galeriaIMG/Closets/img15.jpg"
import closet16 from "./../images/productos/galeriaIMG/Closets/img16.jpg"
import closet17 from "./../images/productos/galeriaIMG/Closets/img17.jpg"

// GALERIA PUERTAS
import puertas1 from "./../images/productos/galeriaIMG/Puertas/img1.jpg"
import puertas2 from "./../images/productos/galeriaIMG/Puertas/img2.jpg"
import puertas3 from "./../images/productos/galeriaIMG/Puertas/img3.jpg"
import puertas4 from "./../images/productos/galeriaIMG/Puertas/img4.jpg"

// GALERIA TV
import tv1 from "./../images/productos/galeriaIMG/Tv/img1.jpg"
import tv2 from "./../images/productos/galeriaIMG/Tv/img2.jpg"
import tv3 from "./../images/productos/galeriaIMG/Tv/img3.jpg"
import tv4 from "./../images/productos/galeriaIMG/Tv/img4.jpg"
import tv5 from "./../images/productos/galeriaIMG/Tv/img5.jpg"
import tv6 from "./../images/productos/galeriaIMG/Tv/img6.jpg"
import tv7 from "./../images/productos/galeriaIMG/Tv/img7.jpg"
import tv8 from "./../images/productos/galeriaIMG/Tv/img8.jpg"
import tv9 from "./../images/productos/galeriaIMG/Tv/img9.jpg"
import tv10 from "./../images/productos/galeriaIMG/Tv/img10.jpg"
import tv11 from "./../images/productos/galeriaIMG/Tv/img11.jpg"
import tv12 from "./../images/productos/galeriaIMG/Tv/img12.jpg"
import tv13 from "./../images/productos/galeriaIMG/Tv/img13.jpg"
import tv14 from "./../images/productos/galeriaIMG/Tv/img14.jpg"
import tv15 from "./../images/productos/galeriaIMG/Tv/img15.jpg"


import galeriaProducto1 from "./../images/productos/galeriaIMG/galeriaImg1.png"
import galeriaProducto2 from "./../images/productos/galeriaIMG/galeriaImg2.png"
import galeriaProducto3 from "./../images/productos/galeriaIMG/galeriaImg3.png"
import galeriaProducto4 from "./../images/productos/galeriaIMG/galeriaImg4.png"

import producto1 from"./../images/productos/galeria1.png"
import producto2 from"./../images/productos/galeria2.png"
import producto3 from"./../images/productos/galeria3.png"
import producto4 from"./../images/productos/galeria4.png"

function ProductosGallery() {
    let {id} = useParams()

    const initialValues = {
        id: 0,
        header: "",
        desc: "",
        title: () => {
            return 0
        },
        gallery: [{
            imagen: ""
        }]
    }

    const initialValuesGaleria = {
        title: "",
        desc: "",
        by: "",
        gallery: [
            {
                image: ""
            }
        ]
    }
    const [productoCurrent, setProductoCurrent] = useState(initialValues)
    const [galeriaProducto, setGaleriaProducto] = useState(initialValuesGaleria)
    const [show, setShow] = useState(false);

    

    const productos = [
    {
        id: 0,
        header: header1,
        desc: "Cocinas Integrales",
        title: () => {
            return(
                <h1 className='text-white d-inline-block fnt-lemonmilk-light'>
                    CONSTRUIMOS <b>LA <br/>COCINA DE TUS SUEÑOS</b>
                    <div className='border-white border-bottom border-5 w-100 mt-4'></div>
                </h1>
            )
        },
        gallery: [
            {
                imagen: cocina1
            },{
                imagen: cocina2
            },{
                imagen: cocina3
            },{
                imagen: cocina4
            },{
                imagen: cocina5
            },{
                imagen: cocina6
            }
        ] 
    },
    {
        id: 1,
        header: header2,
        desc: "Closets",
        title: () => {
            return(
                <h1 className='text-white d-inline-block fnt-lemonmilk-light'>
                    UN ÁREA CON <br/><b>FUNCIONALIDAD Y RESISTENCIA</b>
                    <div className='border-white border-bottom border-5 w-100 mt-4'></div>
                </h1>
            )
        },
        gallery: [
            {
                imagen: closet1
            },{
                imagen: closet2
            },{
                imagen: closet3
            },{
                imagen: closet4
            },{
                imagen: closet5
            },{
                imagen: closet6
            },{
                imagen: closet7
            },{
                imagen: closet8
            },{
                imagen: closet9
            },{
                imagen: closet10
            },{
                imagen: closet11
            },{
                imagen: closet12
            },{
                imagen: closet13
            },{
                imagen: closet14
            },{
                imagen: closet15
            },{
                imagen: closet16
            },{
                imagen: closet17
            }
        ]
    },
    {
        id: 2,
        header: header3,
        desc: "Centros de entretenimiento",
        title: () => {
            return(
                <h1 className='text-white d-inline-block fnt-lemonmilk-light'>
                    CONVIERTE TU SALA EN EL MEJOR <br/><b>CENTRO DE ENTRETENIMIENTO</b>
                    <div className='border-white border-bottom border-5 w-100 mt-4'></div>
                </h1>
            )
        },
        gallery: [
            {
                imagen: tv1
            },{
                imagen: tv2
            },{
                imagen: tv3
            },{
                imagen: tv4
            },{
                imagen: tv5
            },{
                imagen: tv6
            },{
                imagen: tv7
            },{
                imagen: tv8
            },{
                imagen: tv9
            },{
                imagen: tv10
            },{
                imagen: tv11
            },{
                imagen: tv12
            },{
                imagen: tv13
            },{
                imagen: tv14
            },{
                imagen: tv15
            }
        ]
    },
    {
        id: 3,
        header: header4,
        desc: "Puertas",
        title: function (e) {
            return(
                <h1 className='text-white d-inline-block fnt-lemonmilk-light'>
                    PROYECTOS <b>A LA <br/>MEDIDA DE TUS NECESIDADES</b>
                    <div className='border-white border-bottom border-5 w-100 mt-4'></div>
                </h1>
            )
        },
        gallery: [
            {
                imagen: puertas1
            },{
                imagen: puertas2
            },{
                imagen: puertas3
            },{
                imagen: puertas4
            }
        ]

    }
    ]

    const productosGaleria = [
        {
            title: "Tamarindo, Huanacaxtle",
            desc: "Hermosa cocina con teca natural para todas las áreas con una combinación de MDF blanco liso y tipo shaker. En el caso de las encimeras se utilizó porcelanato italiano Aria White. Como parte del proyecto, el cliente optó por renovar espacios en su habitación. ",
            by: "Puerto Vallarta, Jalisco",
            gallery: [
                {
                    original: galeriaProducto4 ,
                    thumbnail: galeriaProducto1,
                    originalClass: "img-fluid w-100"
                },
                {
                    original: galeriaProducto4,
                    thumbnail: galeriaProducto2,
                    originalClass: "img-fluid w-100"
                },
                {
                    original: galeriaProducto4,
                    thumbnail: galeriaProducto3,
                    originalClass: "img-fluid w-100"
                },
                {
                    original: galeriaProducto4,
                    thumbnail: galeriaProducto4,
                    originalClass: "img-fluid w-100"
                },
            ]
        }
    ]

    const productosBtn = [
        {
          id: 0,
          imagen: producto1,
          desc: "Cocinas Integrales"
        },
        {
          id: 1,
          imagen: producto2,
          desc: "Closets"
        },
        {
          id: 2,
          imagen: producto3,
          desc: "Centros de entretenimiento"
        },
        {
          id: 3,
          imagen: producto4,
          desc: "Puertas"
        }
      ]
      useEffect(() => {
        window.scrollTo(0, 0)
        productos[id] && setProductoCurrent(productos[id])
        AOS.init();
    }, [id])

    const handleClose = () => setShow(false);
    const handleShow = (galeria) => {
        setGaleriaProducto(galeria)
        setShow(true);
    }
    window.onresize = (e) => {
        AOS.refresh()
    };

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
      };


    return productoCurrent && (
        <div className="overflow-hidden">
            <Container fluid className="vh-100 d-flex align-items-end pb-5 position-relative" style={{zIndex: "-1"}} data-aos="zoom-in-down">
                <Container >
                    <Row>
                        <Col>
                            {productoCurrent.title()}
                        </Col>
                    </Row>
                </Container>
                <img loading="lazy" src={productoCurrent.header} className="position-absolute top-50 start-50 translate-middle object-fit-cover w-100 h-100" style={{zIndex: "-1"}}/>
            </Container>
            <Container fluid className="mt-5">
                <Row data-aos="zoom-in-right">
                    <Col lg={4} className='col-4 py-5 px-0'>
                        <div className='col-12 bg-secondary pt-5 pb-4'></div>
                    </Col>
                    <Col lg={1} className='col-1 py-5 px-0'>
                        <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
                    </Col>
                    <Col className='py-lg-5 pt-0 d-flex align-items-center'>
                        <h1 data-aos="zoom-in-down" className="fnt-lemonmilk-bold text-start text-uppercase">{productoCurrent.desc}</h1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='overflow-hidden'>
                    <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                        {productoCurrent.gallery.map((val,i) => {
                            return  <button data-aos="zoom-out" className='transitions scale border-0 rounded-0 p-0' key={i} onClick={() => productosGaleria && handleShow(productosGaleria[0])}>
                                        <img  loading="lazy" src={val.imagen} alt={val.desc}  className="object-fit-cover img-fluid p-1 text-center " />
                                    </button>
                        })}
                    </Masonry>
                </Row>
                {/* {productoCurrent.gallery[0].imagen && 
                    <Row className="productosGaleria">
                        <Col lg={3} className="h-100">
                            <Row className="h-100">
                                <Col lg={12} className="h-50 p-2">
                                    <button data-aos="zoom-out" className="h-100 w-100 border-0 transitions scale rounded-0 p-0" onClick={() => productosGaleria && handleShow(productosGaleria[0])}>
                                        <img  loading="lazy" src={productoCurrent.gallery[0].imagen} alt={productoCurrent.desc} className="h-100 w-100 object-fit-cover" />
                                    </button>
                                </Col>
                                <Col lg={12} className="h-50 p-2">
                                    <button data-aos="zoom-out" className="h-100 w-100 border-0 transitions scale rounded-0 p-0" onClick={() => productosGaleria && handleShow(productosGaleria[0])}>
                                        <img  loading="lazy" src={productoCurrent.gallery[1].imagen} alt={productoCurrent.desc} className="h-100 w-100 object-fit-cover" />
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} className="h-100 p-2">
                            <button data-aos="zoom-out" className="h-100 w-100 border-0 transitions scale rounded-0 p-0" onClick={() => productosGaleria && handleShow(productosGaleria[0])}>
                                <img  loading="lazy" src={productoCurrent.gallery[2].imagen} alt={productoCurrent.desc} className="h-100 w-100 object-fit-cover" />
                            </button>
                        </Col>
                        <Col lg={6} className="h-100 px-2">
                            <Row  className="h-50">
                                <Col lg={12} className="h-100 p-2">
                                    <button data-aos="zoom-out" className="h-100 w-100 border-0 transitions scale rounded-0 p-0" onClick={() => productosGaleria && handleShow(productosGaleria[0])}>
                                        <img  loading="lazy" src={productoCurrent.gallery[3].imagen} alt={productoCurrent.desc} className="h-100 w-100 object-fit-cover" />
                                    </button>
                                </Col>
                            </Row>
                            <Row  className="h-50">
                                <Col lg={6}  className="h-100 p-2">
                                    <button data-aos="zoom-out" className="h-100 w-100 border-0 transitions scale rounded-0 p-0" onClick={() => productosGaleria && handleShow(productosGaleria[0])}>
                                        <img  loading="lazy" src={productoCurrent.gallery[4].imagen} alt={productoCurrent.desc} className="h-100 w-100 object-fit-cover" />
                                    </button>
                                </Col>
                                <Col lg={6}  className="h-100 p-2">
                                    <button data-aos="zoom-out" className="h-100 w-100 border-0 transitions scale rounded-0 p-0" onClick={() => productosGaleria && handleShow(productosGaleria[0])}>
                                        <img  loading="lazy" src={productoCurrent.gallery[5].imagen} alt={productoCurrent.desc} className="h-100 w-100 object-fit-cover" />
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                } */}
                <Row>
                    <Col className='d-flex align-items-center justify-content-center flex-column'>
                        <h3 data-aos="zoom-in-down" className='fnt-helvetica-bold text-center'>Asesoramos y acompañamos tus ideas <br/>para generar ambientes de experiencias</h3>
                        <h4 data-aos="zoom-in-up" className='fnt-helvetica-lt-std-light text-center mt-2'>Cotiza tu proyecto</h4>
                        <Link data-aos="zoom-in-left" to="/contacto/" className="btn btn-outline-secondary d-inline-block border border-3 border-secondary mt-4 rounded-0">Contactanos</Link>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="mt-5">
                <Row data-aos="zoom-in-right">
                    <Col lg={4} className='col-4 py-5 px-0'>
                        <div className='col-12 bg-secondary pt-5 pb-4'></div>
                    </Col>
                    <Col lg={1} className='col-1 py-5 px-0'>
                        <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
                    </Col>
                    <Col className='py-5 d-flex align-items-center'>
                        <h1 data-aos="zoom-in-down" className="fnt-lemonmilk-bold text-justify">GALERÍA DE <b>PRODUCTOS</b></h1>
                    </Col>
                </Row>
            </Container>
            <Container className="mb-5">
                <Row>
                {
                    productosBtn.map((val,i) => {
                    return(
                        <Col data-aos="fade-up" data-aos-delay={`${i}00`} lg={3} style={{height: "30%"}} className="position-relative my-3 p-2" key={i}>
                            <img alt={val.desc}  loading="lazy" src={val.imagen} className="object-fit-cover h-100 w-100" />
                            <div className='w-100 d-flex justify-content-center align-items-center flex-column position-absolute bottom-0 start-50 translate-middle-x mb-3'>
                                <h5 className="text-center text-white w-100 px-2">{val.desc}</h5>
                                <Link to={`/productos/${val.id}`} className='btn btn-outline-light rounded-0 d-inline-block fs-6 px-3 py-1'>Ver más</Link>
                            </div>
                        </Col>
                    )
                    })
                }
                </Row>
            </Container>
           { galeriaProducto &&
           <Modal centered show={show} onHide={handleClose} size="lg">
                <Modal.Header className='border-0 pb-0 float-end' closeButton></Modal.Header>
                <Modal.Body className='pt-1'>
                    <Row>
                        <Col data-aos="fade-down" className='col-lg-6 d-flex align-items-center justify-content-center p-4'>
                            <ImageGallery 
                                items={galeriaProducto.gallery} 
                                className="w-100 img-fluid"
                            />
                        </Col>
                        <Col className='col-lg-6 d-flex align-items-center justify-content-center'>
                            <div className='p-4'>
                                <h4 data-aos="fade-left" className='fnt-helvetica-bold'>{galeriaProducto.title}</h4>
                                <p data-aos="fade-right" className='fnt-helvetica-lt-std-light text-justify mt-4'>{galeriaProducto.desc}</p>
                                <p data-aos="fade-down" className='fnt-helvetica-bold text-end mt-5'>{galeriaProducto.by}</p>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            }
        </div>
        
    )
}

export default ProductosGallery