import React, { useEffect, useRef } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import SendEmail from '../includes/SendEmail'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Swal from 'sweetalert2'
import margen from "./../images/contacto/margen.png"

function Contacto() {
    const form = useRef();

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init();
    }, [])

    const initialValues = {
        nombre: "",
        correo: "",
        mensaje: "",
    }

    const validationSchema = Yup.object().shape({
        correo: Yup
            .string()
            .email("Correo invalido")
            .required("Escribe tu correo electronico"),
        nombre: Yup
            .string()
            .required("Escribe tu nombre"),
        mensaje: Yup
            .string()
            .required("Escribenos un mensaje o comentario"),
    })

    const onSubmit = () => {
        SendEmail("template_y4koybp", form.current);
         Swal.fire({
            title: 'Bien!',
            text: 'Se envio tu correo correctamente',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          document.querySelector("form").reset()
    }
    window.onresize = (e) => {
        AOS.refresh()
    };

    return (
    <div className="overflow-hidden">
        <Container fluid className="bg-contacto min-vh-100 bg-pCenter-sCover d-flex align-items-end pb-5" data-aos="zoom-in-up">
            <Container className='mb-5'>
                <Row>
                    <Col lg={12} className="">
                        <h1 className='fnt-lemonmilk-light text-white position-relative d-inline py-5 pe-5'>
                            DISEÑO EXCLUSIVO Y <br className="d-none d-lg-block"/> <b>MONTAJE PROFESIONAL</b>
                            <img loading="lazy" src={margen} className="position-absolute top-50 end-0 translate-middle-y w-75 d-none d-lg-block"/>
                        </h1>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container fluid className="mt-5">
            <Row data-aos="zoom-in-right">
                <Col className='col-8 col-lg-4 py-lg-5 pt-5 px-0'>
                    <div className='col-12 bg-secondary pt-5 pb-4'></div>
                </Col>
                <Col className='col-2 col-lg-1 py-lg-5 pt-5 px-0'>
                    <div className='col-4 bg-secondary bg-opacity-75 pt-5 pb-4'></div>
                </Col>
                <Col className='col-12 col-lg-7 py-5 pe-4 d-flex justify-content-between align-items-end'>
                    <h3 data-aos="zoom-in-down" className="fnt-helvetica-lt-std-light text-justify">La clave de nuestro éxito es escuchar a nuestros clientes como nadie lo hace, <b>¡Gracias por formar parte de nosotros!</b> </h3>
                </Col>
            </Row>
        </Container>
        <Container className="px-lg-5 bg-pCenter-sCover bg-formulario mt-5" data-aos="zoom-in-up">
            <Row>
                <Col lg={5} className="p-0 pt-5 pt-lg-0 px-lg-5">
                    <div data-aos="zoom-in-right" className='bg-secondary bg-opacity-50 d-flex align-items-center justify-content-center h-100 px-4 py-3 py-lg-0'>
                        <h1 className="text-center text-white my-auto fnt-helvetica-bold">Envíanos <br/>un<br/> mensaje</h1>
                    </div>
                </Col>
                <Col className='p-lg-5'>
                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                        <Form ref={form} className='d-flex align-items-start justify-content-center flex-column mt-lg-4 px-4 pt-5'>
                            <Field data-aos="zoom-in-up" className="form-control rounded-0 bg-white border-0" name="nombre" placeholder="Nombre" />
                            <ErrorMessage clsassName='text-danger pt-5' name="nombre" component="span" /> <br/>
                            <Field data-aos="zoom-in-up" className="form-control rounded-0 bg-white border-0" name="correo" placeholder="Correo" />
                            <ErrorMessage clsassName='text-danger pt-5' name="correo" component="span" /> <br/>
                            <Field data-aos="zoom-in-up" as="textarea" className="form-control rounded-0 bg-white border-0" name="mensaje" placeholder="Mensaje" rows="5"/>
                            <ErrorMessage clsassName='text-danger pt-5' name="mensaje" component="span" /> <br/>
                            <button data-aos="zoom-in-right" className="btn bg-warning bg-opacity-75 rounded-0 text-white px-4" type="submit">Enviar</button>
                        </Form>
                    </Formik>
                    <div className='float-end mb-5'>
                        <a data-aos="zoom-in-down" href='https://www.facebook.com/Bloken-100969762721498/' target="_blank" className='btn btn-outline-light rounded-0 border-0 py-0 px-1 fs-1'><i className='bx bxl-facebook-square'></i></a>
                        <a data-aos="zoom-in-down" href='https://instagram.com/blokenmexico?igshid=Y2ZmNzg0YzQ=' target="_blank" className='btn btn-outline-light rounded-0 border-0 py-0 px-1 fs-1'><i className='bx bxl-instagram-alt'></i></a>
                        <a data-aos="zoom-in-down" href='https://www.linkedin.com/' target="_blank" className='btn btn-outline-light rounded-0 border-0 py-0 px-1 fs-1'><i className='bx bxl-linkedin-square' ></i></a>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className="bg-contacto-opacity py-5" data-aos="zoom-in-up">
            <Container>
                <Row>
                    <Col lg={4} className="text-center d-flex flex-column align-items-center justify-content-center">
                        <i data-aos="zoom-in-up" className='bx bx-envelope display-1 text-orange'></i>
                        <a data-aos="zoom-in-up" className="btn-ver-mas px-3 py-2 rounded-0 border-0 fs-5" href="mailto:hola@blocken.mx" target="_blank">hola@bloken.mx</a>
                        <a data-aos="zoom-in-up" className="btn-ver-mas px-3 py-2 rounded-0 border-0 mt-1 fs-5" href="mailto:jcervantes@blocken.mx" target="_blank">jcervantes@bloken.mx</a>
                    </Col>
                    <Col lg={4} className="text-center d-flex flex-column align-items-center justify-content-center mt-5 mt-lg-0">
                        <i data-aos="zoom-in-down" className='bx bx-phone display-1 text-orange'></i>
                        <a data-aos="zoom-in-down" className="btn-ver-mas px-3 py-2 rounded-0 border-0 fs-5" href="tel:3316567598" target="_blank">(33) 1656 7598</a>
                        <a data-aos="zoom-in-down" className="btn-ver-mas px-3 py-2 rounded-0 border-0 fs-5" href="tel:3322133285" target="_blank">(33) 2213 3285</a>
                    </Col>
                    <Col lg={4} className="text-center d-flex flex-column align-items-center justify-content-center mt-5 mt-lg-0">
                        <i data-aos="zoom-in-up" className='bx bx-time display-1 text-orange'></i>
                        <p data-aos="zoom-in-up" className="fs-5 text-orange mb-1">Lunes a viernes 9:00 am a 8:00 pm</p>
                        <p data-aos="zoom-in-up" className="fs-5 text-orange mb-0 mt-1">Sabado 8:00 am a 4:00 pm</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    </div>
  )
}

export default Contacto